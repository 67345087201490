import React from 'react';
import LogoEvento from '../assets/images/logo.svg';
import LogoMBC from '../assets/images/icone_mbc.svg';

interface HeaderWithProgressProps {
  step: number;
  progress: number;
}

const HeaderWithProgress: React.FC<HeaderWithProgressProps> = ({ step, progress }) => {
  return (
    <div className="bg-customBlack text-white fixed w-full z-50 shadow-md">
      <div className="flex justify-between max-w-screen-xl mx-auto px-8 py-4 w-[370px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1400px]">
        <img src={LogoEvento} alt="Lumine"  className="w-[145px] lg:w-[180px]" />
        <img src={LogoMBC} alt="Lumine" className="w-[35px] lg:w-[46px]" />
      </div>
      <div className="h-2 border-t border-gray-700">
        <div
          className="bg-bgBlueLight h-2"
          style={{
            width: `${progress}%`,
            boxShadow: '1px 1px 22px 1px #0090FF',
          }}
        ></div>
      </div>
    </div>
  );
};

export default HeaderWithProgress;
