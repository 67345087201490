import React, { useState, useEffect } from 'react';
import {
  // eslint-disable-next-line
  BrowserRouter as Router,
  Route,
  Routes,
  useNavigate,
  useLocation,
  Navigate,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Header from './components/Header';
import Footer from './components/Footer';
import Step0 from './pages/Step0';
import Step1 from './pages/Step1';
import Step2 from './pages/Step2';
import Step3 from './pages/Step3';
import Step4 from './pages/Step4';
import Step5 from './pages/Step5';
import Step6 from './pages/Step6';
import Step7 from './pages/Step7';
import Step8 from './pages/Step8';
import Processing from './pages/Processing';
import Success from './pages/Success';
import { useScore } from './context/ScoreContext';
import Profile from './pages/Profile';
import { usePlataforma } from './context/PlataformaContext';

const App = () => {
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const { surveyData, setCurrentStep } = useScore();
  const { setPlataforma } = usePlataforma();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    const stepMatch = currentPath.match(/\/step(\d+)/);
    const stepNumber = stepMatch ? parseInt(stepMatch[1], 10) : 0;

    if (stepNumber > surveyData.currentStep) {
      navigate(`/`);
    }
  }, [surveyData.currentStep, surveyData.email, location.pathname, navigate]);

  //Query String
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const plataforma = query.get('origem');
    if (plataforma) {
      console.log(`Origem: ${plataforma}`);
      setPlataforma(plataforma);
    }
  }, [location.search, setPlataforma]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    scrollToTop();
  }, [surveyData.currentStep]);

  const nextStep = (newDetails = {}) => {
    setLoading(true);
    setDetails({ ...details, ...newDetails });

    if (surveyData.currentStep < 8) {
      setCurrentStep(surveyData.currentStep + 1);
      navigate(`/step${surveyData.currentStep + 1}`);
      scrollToTop();
    } else if (surveyData.currentStep === 8) {
      navigate('/processing');
      setTimeout(() => {
        navigate('/success');
        scrollToTop();
      }, 3000); // Simulating processing time
    }

    setLoading(false);
  };

  const prevStep = () => {
    if (surveyData.currentStep > 0 && surveyData.currentStep <= 8) {
      setCurrentStep(surveyData.currentStep - 1);
      navigate(`/step${surveyData.currentStep - 1}`);
      scrollToTop();
    }
  };

  const showFooter =
    surveyData.currentStep >= 2 &&
    surveyData.currentStep <= 8 &&
    location.pathname !== '/processing' &&
    location.pathname !== '/success' &&
    location.pathname !== '/profile';
  const showHeader =
    surveyData.currentStep >= 1 &&
    surveyData.currentStep <= 8 &&
    location.pathname !== '/processing' &&
    location.pathname !== '/success' &&
    location.pathname !== '/profile';

  const nextDisabled =
    !surveyData.selections[`step${surveyData.currentStep}`] || 
    !surveyData.selections[`step${surveyData.currentStep}`].length;

  return (
    <div className="relative min-h-screen text-white">
      {showHeader && (
        <Header
          step={surveyData.currentStep}
          progress={(surveyData.currentStep / 8) * 100}
        />
      )}
      <div className="flex flex-col min-h-screen">
        <TransitionGroup className="flex-grow">
          <CSSTransition key={location.key} classNames="fade" timeout={300}>
            <div className="flex-grow">
              <Routes location={location}>
                <Route path="/" element={<Step0 onNext={() => nextStep()} />} />
                <Route path="/step0" element={<Step0 onNext={() => nextStep()} />} />
                <Route path="/step1" element={<Step1 onNext={(details) => nextStep(details)} />} />
                <Route path="/step2" element={<Step2 onNext={(details) => nextStep(details)} onBack={prevStep} />} />
                <Route path="/step3" element={<Step3 onNext={(details) => nextStep(details)} onBack={prevStep} />} />
                <Route path="/step4" element={<Step4 onNext={(details) => nextStep(details)} onBack={prevStep} />} />
                <Route path="/step5" element={<Step5 onNext={(details) => nextStep(details)} onBack={prevStep} />} />
                <Route path="/step6" element={<Step6 onNext={(details) => nextStep(details)} onBack={prevStep} />} />
                <Route path="/step7" element={<Step7 onNext={(details) => nextStep(details)} onBack={prevStep} />} />
                <Route path="/step8" element={<Step8 onNext={(details) => nextStep(details)} onBack={prevStep} />} />
                <Route path="/processing" element={<Processing />} />
                <Route path="/success" element={<Success />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </div>
          </CSSTransition>
        </TransitionGroup>
        {showFooter && (
          <Footer
            onNext={nextStep}
            onBack={prevStep}
            nextDisabled={nextDisabled}
            backDisabled={surveyData.currentStep === 0}
            isLastStep={surveyData.currentStep === 8}
            loading={loading}
            isStep8={surveyData.currentStep === 8} // Passar isStep8 como true quando estiver na etapa 8
          />
        )}
      </div>
    </div>
  );
};

export default App;
