import React from 'react';
import clsx from 'clsx';

interface SelectionOptionProps {
  option: string;
  isSelected: boolean;
  onSelect: (value: string) => void;
}

const SelectionOption: React.FC<SelectionOptionProps> = ({ option, isSelected, onSelect }) => {
  return (
    <label
      className={clsx('bg-greySolidNeutral2 text-sm md:text-[17px] flex items-center gap-4 cursor-pointer rounded-lg p-4', isSelected ? 'bg-inputSelected' : '')}
    >
      <input
        type="checkbox"
        name="preference"
        value={option}
        className="h-4 w-4 custom-checkbox"
        checked={isSelected}
        onChange={() => onSelect(option)}
      />
      <p className='w-[240px] sm:w-full'>{option}</p>
    </label>
  );
};

export default SelectionOption;
