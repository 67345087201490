import axios, { Method } from 'axios';
import { environment } from '../environments/environment.prod';

const API_URL = environment.apiUrl;

interface FieldValue {
  field: string;
  value: string | number;
}

interface Contact {
  email: string;
  firstName: string;
  gender?: string;
  phone?: string;
  id?: number;
  tags?: number[];
  utm?: string;
  fieldValues?: FieldValue[];
}

class ActiveCampaignService {
  private async enviarSolicitacao(url: string, method: Method, data?: any) {
    try {
      const response = await axios({
        method,
        url,
        headers: {
          'Content-Type': 'application/json',
        },
        data,
      });
      return response.data;
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        console.error(
          'Erro ao enviar a solicitação:',
          error.response?.data || error.message,
        );
      } else {
        console.error('Erro desconhecido:', error);
      }
      throw error;
    }
  }

  private cleanProfileValue(value: string): string {
    return value.replace(/\|/g, '').trim();
  }

  public async handleActiveCampaignContact(
    nome: string,
    email: string,
    phone: string,
    gender: string,
    utm: string,
  ) {
    const contatoData: Contact = {
      email,
      firstName: nome,
      phone,
      gender,
      utm: utm,
    };

    const response = await this.enviarSolicitacao(
      `${API_URL}/contacts`,
      'POST',
      { contact: contatoData },
    );

    // Limpar o valor do profile antes de retornar
    if (response.profile) {
      response.profile = this.cleanProfileValue(response.profile);
    }

    return response;
  }

  public async updateProfiles(email: string, profiles: string) {
    const updateData = {
      email: email,
      profiles: profiles
    };

    console.log('Dados para updateProfiles:', updateData);
    
    await this.enviarSolicitacao(
      `${API_URL}/updateProfile`, 
      'POST', 
      updateData);
  }
  
}

export const activeCampaignService = new ActiveCampaignService();
