import React, { useState } from 'react';
import { useScore } from '../context/ScoreContext';
import StepHeader from '../components/StepHeader';
import SelectionOption from '../components/SelectionOption';

interface Step7Props {
  onNext: () => void;
  onBack: () => void;
}

const Step7: React.FC<Step7Props> = ({ onNext, onBack }) => {
  const { surveyData, updateSelections } = useScore();
  const [selectedOption, setSelectedOption] = useState<string>(surveyData.selections['step7']?.[0] || '');

  const handleSelect = (value: string) => {
    setSelectedOption(value);
    updateSelections('step7', [value]);
  };

  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  const options = [
    "Com disciplina e determinação, buscando momentos de oração intensa e focada",
    "Com constância e regularidade, mantendo uma prática de oração estável",
    "Com entusiasmo e envolvimento emocional, buscando a conexão espiritual",
    "Com profundidade e introspecção, buscando momentos de silêncio e reflexão"
  ];

  return (
    <div className="flex flex-col items-center text-white min-h-screen max-w-screen-xl px-8 py-4 m-auto w-[370px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1400px]">

      <StepHeader
        stepNumber={6}
        title="Como costuma ser a sua prática de oração?"
        description="Selecione uma opção:"
      />

      <div className="mb-28 flex-grow overflow-y-auto md:mt-5 mr-4 ml-4 m-auto w-full">
        <form className="grid grid-cols-1 gap-2 mx-auto md:px-0 w-full">
          {options.map(option => (
            <SelectionOption
              key={option}
              option={option}
              isSelected={selectedOption === option}
              onSelect={handleSelect}
            />
          ))}
        </form>
      </div>
     
    </div>
  );
};

export default Step7;
