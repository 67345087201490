import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { ScoreProvider } from './context/ScoreContext';
import { PlataformaProvider } from './context/PlataformaContext';


ReactDOM.render(
  <React.StrictMode>
    <ScoreProvider>
      <PlataformaProvider>
        <Router>
          <App />
        </Router>
      </PlataformaProvider>
    </ScoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
