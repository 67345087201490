import React from 'react';

interface StepHeaderProps {
  stepNumber: number;
  title: string;
  description: string;
}

const StepHeader: React.FC<StepHeaderProps> = ({ stepNumber, title, description }) => {
  return (
    <div className='bg-customBlack mt-[20px] lg:mt-[15px] 2xl:mt-[25px] md:mt-0 md:mb-0 mb-4 md:relative md:z-0 m-auto w-full'>
      <div className='w-full mx-auto pt-4 md:mt-10'>
        <h2 className='leading-tight text-[24px] font-[500] mt-16'>
          <span className='text-bgBlueLight'>{stepNumber}. </span>
          {title}
        </h2>
        <p className='mt-8 text-base font-light text-greySolidNeutral11 md:text-base'>{description}</p>
      </div>
    </div>
  );
};

export default StepHeader;
