import React from 'react';
import clsx from 'clsx';

interface SelectionOptionNoInputProps {
  option: string;
  isSelected: boolean;
  onSelect: (value: string) => void;
}

const SelectionOptionNoInput: React.FC<SelectionOptionNoInputProps> = ({ option, isSelected, onSelect }) => {
  const handleClick = () => {
    onSelect(option);
  };

  return (
    <div
      className={clsx('inline-block bg-greySolidNeutral2 text-sm flex items-center gap-2 cursor-pointer border border-greySolidNeutral2 rounded-full p-2 sm:p-4', isSelected ? 'bg-inputSelected' : '')}
      onClick={handleClick}
    >
      <p className="text-sm">{option}</p>
      {isSelected && <span className="text-greySolidNeutral5">x</span>}
    </div>
  );
};

export default SelectionOptionNoInput;
