import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PlataformaContextProps {
  plataforma: string;
  setPlataforma: React.Dispatch<React.SetStateAction<string>>;
}

const PlataformaContext = createContext<PlataformaContextProps | undefined>(undefined);

interface PlataformaProviderProps {
  children: ReactNode;
}

export const PlataformaProvider = ({ children }: PlataformaProviderProps) => {
  const [plataforma, setPlataforma] = useState<string>('');

  return (
    <PlataformaContext.Provider value={{ plataforma, setPlataforma }}>
      {children}
    </PlataformaContext.Provider>
  );
};

export const usePlataforma = (): PlataformaContextProps => {
  const context = useContext(PlataformaContext);
  if (!context) {
    throw new Error('usePlataforma must be used within a PlataformaProvider');
  }
  return context;
};
