import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface SurveyData {
  currentStep: number;
  selections: { [key: string]: string[] };
  score: number;
  email?: string;
  gender?: string;
}

interface ScoreContextProps {
  surveyData: SurveyData;
  totalScore: number;
  profiles: { [key: string]: number };
  setSurveyData: (data: SurveyData) => void;
  updateSelections: (step: string, selections: string[], updateProfiles?: boolean) => void;
  resetScore: () => void;
  setEmail: (email: string) => void;
  setGender: (gender: string) => void;
  addPointsToProfile: (profile: string, points?: number) => void;
  setCurrentStep: (step: number) => void;
  resetProfilePoints: (step: string) => void;
}

const ScoreContext = createContext<ScoreContextProps | undefined>(undefined);

const stepMapping: { [key: string]: { [key: string]: string[] } } = {
  'step2': {
    "Liderar e ser o centro das atenções": ['São João Paulo II', 'Santa Clara de Assis'],
    "Observar e participar de conversas mais calmas": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Se enturmar rapidamente e fazer novas amizades": ['São Francisco de Assis', 'Santa Teresa d’Avilla'],
    "Ficar em um cantinho apenas curtindo o grupo": ['São Padre Pio', 'Santa Teresinha do Menino Jesus']
  },
  'step3': {
    "Me adapto rapidamente e para encontrar novas soluções": ['São João Paulo II', 'Santa Clara de Assis'],
    "Prefiro manter a calma e reavaliar a situação antes de decidir": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Vejo as mudanças como oportunidades para explorar algo diferente": ['São Francisco de Assis', 'Santa Teresa d’Avilla'],
    "Posso me sentir desconfortável no início, mas me ajusto com o tempo": ['São Padre Pio', 'Santa Teresinha do Menino Jesus']
  },
  'step4': {
    "Intenso": ['São João Paulo II', 'Santa Clara de Assis'],
    "Estável": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Entusiasmado": ['São Francisco de Assis', 'Santa Teresa d’Avilla'],
    "Contemplativo": ['São Padre Pio', 'Santa Teresinha do Menino Jesus']
  },
  'step5': {
    "Humildade": ['São Francisco de Assis', 'Santa Clara de Assis'],
    "Caridade": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Perseverança": ['São João Paulo II', 'Santa Teresa d’Avilla'],
    "Confiança em Deus": ['São Padre Pio', 'Santa Teresinha do Menino Jesus']
  },
  'step6': {
    "Expressando sua espiritualidade através de ações e serviço aos outros": ['São João Paulo II', 'Santa Clara de Assis'],
    "Buscando entender a teologia e as tradições da fé": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Participando de grupos de oração e práticas devocionais": ['São Francisco de Assis', 'Santa Teresa d’Avilla'],
    "Meditando e refletindo sobre ensinamentos espirituais": ['São Padre Pio', 'Santa Teresinha do Menino Jesus']
  },
  'step7': {
    "Com disciplina e determinação, buscando momentos de oração intensa e focada": ['São João Paulo II', 'Santa Clara de Assis'],
    "Com constância e regularidade, mantendo uma prática de oração estável": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Com entusiasmo e envolvimento emocional, buscando a conexão espiritual": ['São Francisco de Assis', 'Santa Teresa d’Avilla'],
    "Com profundidade e introspecção, buscando momentos de silêncio e reflexão": ['São Padre Pio', 'Santa Teresinha do Menino Jesus']
  },
  'step8': {
    "Determinado": ['São João Paulo II', 'Santa Clara de Assis'],
    "Corajoso": ['São João Paulo II', 'Santa Clara de Assis'],
    "Líder": ['São João Paulo II', 'Santa Clara de Assis'],
    "Perseverante": ['São João Paulo II', 'Santa Clara de Assis'],
    "Caridoso": ['São João Paulo II', 'Santa Clara de Assis'],
    "Proativo": ['São João Paulo II', 'Santa Clara de Assis'],
    "Carismático": ['São João Paulo II', 'Santa Clara de Assis'],
    "Combativo": ['São João Paulo II', 'Santa Clara de Assis'],
    "Aventureiro": ['São João Paulo II', 'Santa Clara de Assis'],
    "Prestativo": ['São João Paulo II', 'Santa Clara de Assis'],
    "Equilibrado": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Intelectual": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Paciente": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Observador": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Tranquilo": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Confiante na Providência": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Se sacrifica": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Constante": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Calmo": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Sensato": ['São Tomás de Aquino', 'Santa Gianna Beretta Molla'],
    "Comunicativo": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Bondoso": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Apaixonado": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Otimista": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Cativante": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Espontâneo": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Flexível": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Alegre": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Desapegado": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Altruísta": ['Santa Teresa d’Avilla', 'São Francisco de Assis'],
    "Obstinado": ['Santa Teresinha do Menino Jesus', 'São Padre Pio'],
    "Sensível": ['Santa Teresinha do Menino Jesus', 'São Padre Pio'],
    "Leal": ['Santa Teresinha do Menino Jesus', 'São Padre Pio'],
    "Humilde": ['Santa Teresinha do Menino Jesus', 'São Padre Pio'],
    "Empático": ['Santa Teresinha do Menino Jesus', 'São Padre Pio'],
    "Contemplativo": ['Santa Teresinha do Menino Jesus', 'São Padre Pio'],
    "Piedoso": ['Santa Teresinha do Menino Jesus', 'São Padre Pio'],
    "Dedicado": ['Santa Teresinha do Menino Jesus', 'São Padre Pio'],
    "Generoso": ['Santa Teresinha do Menino Jesus', 'São Padre Pio'],
    "Simples": ['Santa Teresinha do Menino Jesus', 'São Padre Pio']
  }
};

export const ScoreProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [surveyData, setSurveyDataState] = useState<SurveyData>(() => {
    const savedData = localStorage.getItem('surveyData');
    return savedData ? JSON.parse(savedData) : { currentStep: 0, selections: {}, score: 0 };
  });
  const [totalScore, setTotalScore] = useState(0);
  const [profiles, setProfiles] = useState<{ [key: string]: number }>({
    "São João Paulo II": 0,
    "Santa Clara de Assis": 0,
    "São Tomás de Aquino": 0,
    "Santa Gianna Beretta Molla": 0,
    "São Francisco de Assis": 0,
    "Santa Teresa d’Avilla": 0,
    "São Padre Pio": 0,
    "Santa Teresinha do Menino Jesus": 0
  });

  useEffect(() => {
    localStorage.setItem('surveyData', JSON.stringify(surveyData));
  }, [surveyData]);

  useEffect(() => {
    console.log('Profiles:', profiles);
  }, [profiles]);

  // Função para atualizar os dados da pesquisa
  const setSurveyData = (data: SurveyData) => {
    setSurveyDataState(data);
  };

  // Função para atualizar as seleções e os perfis
  const updateSelections = (step: string, selections: string[], updateProfiles = true) => {
    if (updateProfiles) {
      resetProfilePoints(step);
      selections.forEach(selection => calculateAndAddPoints(step, selection));
    }
    setSurveyDataState(prevData => ({
      ...prevData,
      selections: { ...prevData.selections, [step]: selections }
    }));
  };

  // Função para adicionar pontos a um perfil
  const addPointsToProfile = (profile: string, points = 1) => {
    setProfiles(prevProfiles => ({
      ...prevProfiles,
      [profile]: (prevProfiles[profile] || 0) + points
    }));
  };

  // Função para resetar os pontos
  const resetScore = () => {
    setSurveyDataState({ currentStep: 0, selections: {}, score: 0, email: '' });
    setTotalScore(0);
    setProfiles({
      "São João Paulo II": 0,
      "Santa Clara de Assis": 0,
      "São Tomás de Aquino": 0,
      "Santa Gianna Beretta Molla": 0,
      "São Francisco de Assis": 0,
      "Santa Teresa d’Avilla": 0,
      "São Padre Pio": 0,
      "Santa Teresinha do Menino Jesus": 0
    });
    localStorage.removeItem('surveyData');
  };

  // Função para resetar os pontos de um perfil específico
  const resetProfilePoints = (step: string) => {
    const previousSelections = surveyData.selections[step] || [];
    previousSelections.forEach(selection => calculateAndAddPoints(step, selection, -1));
  };

  // Função para calcular e adicionar pontos com base na seleção e na etapa
  const calculateAndAddPoints = (step: string, option: string, points = 1) => {
    const relevantMapping = stepMapping[step];
    if (!relevantMapping) return;

    const relevantProfiles = relevantMapping[option];
    if (!relevantProfiles) return;

    relevantProfiles.forEach(profile => addPointsToProfile(profile, points));
  };

  // Função para definir o email
  const setEmail = (email: string) => {
    setSurveyDataState(prevData => ({
      ...prevData,
      email
    }));
  };

    // Função para definir o gênero
    const setGender = (gender: string) => {
      setSurveyDataState(prevData => ({
        ...prevData,
        gender
      }));
    };

  // Função para definir a etapa atual
  const setCurrentStep = (step: number) => {
    setSurveyDataState(prevData => ({
      ...prevData,
      currentStep: step
    }));
  };

  return (
    <ScoreContext.Provider value={{ surveyData, totalScore, profiles, setSurveyData, updateSelections, addPointsToProfile, resetScore, setEmail, setGender, setCurrentStep, resetProfilePoints }}>
      {children}
    </ScoreContext.Provider>
  );
};

export const useScore = (): ScoreContextProps => {
  const context = useContext(ScoreContext);
  if (!context) {
    throw new Error('useScore must be used within a ScoreProvider');
  }
  return context;
};
