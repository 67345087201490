import React, { useEffect } from 'react';
import LogoEvento from '../assets/images/logo.svg';
import LogoMBC from '../assets/images/icone_mbc.svg';
import { useScore } from '../context/ScoreContext';
import HeroImage from '../assets/images/hero.webp';

interface Step0Props {
  onNext: () => void;
}

const Step0: React.FC<Step0Props> = ({ onNext }) => {
  const { resetScore } = useScore();

  useEffect(() => {
    resetScore();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative flex flex-col items-center justify-center text-white min-h-screen bg-cover bg-center" style={{ backgroundImage: `url(${HeroImage})` }}>
      
      <header className="p-[24px] absolute top-4 left-0 right-0 h-16 flex justify-center items-center ">
        <div className="flex justify-between items-center w-[320px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1400px]">
        <img src={LogoEvento} alt="Lumine"  className="w-[145px] lg:w-[180px]" />
        <img src={LogoMBC} alt="Lumine" className="w-[35px] lg:w-[46px]" />
        </div>
      </header>

      <div className='mt-[60vh] flex flex-col items-center text-center md:px-20 mb-20 w-[320px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1920px]'>
        <h1 className="text-[26px] md:text-[32px] lg:text-[38px] 2xl:text-[42px] font-bold mb-[10px] ">
          Faça o teste e descubra
        </h1>
        <p className="mb-8 text-[20px] md:text-[22px] lg:text-[30px] 2xl:text-[32px] 2xl:w-[700px]">
          qual santo teve uma personalidade mais parecida com a sua.
        </p>

        <button onClick={onNext} className="w-full max-w-[500px] text-[20px] lg:text-[24px] p-4 text-white font-bold rounded-full shadow-lg"
          style={{
            borderRadius: '1000px',
            background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), #0090FF',
            backgroundBlendMode: 'soft-light, normal',
            boxShadow: '0px 4px 150px 0px rgba(0, 99, 243, 0.90)'
          }}>
          Fazer o teste
        </button>
      </div>

    </div>
  );
};

export default Step0;
