import React from 'react';
import { useScore } from '../context/ScoreContext';
import { CircularProgress } from '@mui/material';

interface FooterProps {
  onNext: () => void;
  onBack: () => void;
  nextDisabled: boolean;
  backDisabled: boolean;
  isLastStep: boolean;
  loading: boolean;
  isStep8?: boolean; // Adiciona a nova prop
}

const Footer: React.FC<FooterProps> = ({ onNext, onBack, nextDisabled, backDisabled, isLastStep, loading, isStep8 }) => {
  const { surveyData } = useScore();

  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    if (!backDisabled) {
      onBack();
    }
  };

  const totalSelections = surveyData.selections['step8']?.length || 0;

  return (
    <div className="bg-customBlack h-28 text-white p-4 flex justify-between items-center fixed bottom-0 left-1/2 transform -translate-x-1/2 w-[370px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1400px]">
      {isStep8 && (
        <div className='text-xs text-greySolidNeutral10 md:text-sm'>{totalSelections} opções selecionadas</div>
      )}
      <div className='flex w-full gap-2 m-auto'>
        <button
          onClick={handleBack}
          className="w-full md:w-[175px] md:ml-auto md:p-4 text-[16px] font-bold bg-customBlack border border-greySolidGrey6 px-6 py-3 rounded-full disabled:opacity-50"
          disabled={backDisabled}
        >
          Voltar
        </button>
        <button
          onClick={handleNext}
          className="w-full md:w-[175px] md:p-4 md:mr-6 text-[16px] font-bold bg-white font-600 text-black px-6 py-3 rounded-full disabled:opacity-50"
          disabled={nextDisabled || loading}
        >
          {loading ? <CircularProgress size={24} className='icon_loading_login' /> : (isLastStep ? 'Concluir' : 'Próximo')}
        </button>
      </div>
    </div>
  );
};

export default Footer;
