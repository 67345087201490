import React, { useState } from 'react';
import { useScore } from '../context/ScoreContext';
import StepHeader from '../components/StepHeader';
import SelectionOptionNoInput from '../components/SelectionOptionNoInput';

interface Step8Props {
  onNext: () => void;
  onBack: () => void;
}

const Step8: React.FC<Step8Props> = ({ onNext, onBack }) => {
  const { surveyData, updateSelections } = useScore();
  const [selectedOptions, setSelectedOptions] = useState<string[]>(surveyData.selections['step8'] || []);

  const handleSelect = (value: string) => {
    const isSelected = selectedOptions.includes(value);
    let newSelections: string[];
    if (isSelected) {
      newSelections = selectedOptions.filter(option => option !== value);
    } else {
      newSelections = [...selectedOptions, value];
    }
    setSelectedOptions(newSelections);
    updateSelections('step8', newSelections);
  };

  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  const options = [
    "Calmo", "Apaixonado", "Carismático", "Combativo", "Sensível", "Leal",
    "Contemplativo", "Sensato", "Alegre", "Proativo", "Cativante", "Determinado",
    "Empático", "Se sacrifica", "Comunicativo", "Generoso", "Flexível", "Otimista",
    "Corajoso", "Observador", "Paciente", "Simples", "Dedicado", "Caridoso",
    "Constante", "Confiante na Providência", "Prestativo", "Intelectual",
    "Equilibrado", "Altruísta", "Espontâneo", "Humilde", "Piedoso", "Líder",
    "Obstinado", "Perseverante", "Tranquilo", "Desapegado", "Aventureiro", "Bondoso"
  ];
  

  return (
    <div className="flex flex-col items-center text-white min-h-screen max-w-screen-xl px-8 py-4 m-auto w-[370px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1400px]">
      
      <StepHeader
        stepNumber={8}
        title="Quais das características abaixo você se identifica?"
        description="Pode marcar múltiplas opções:"
      />

      
    <div className="mb-28 flex-grow overflow-y-auto md:mt-5 mr-4 ml-4 m-auto w-full">
        <form className="flex flex-wrap gap-2 container mx-auto">
          {options.map(option => (
            <SelectionOptionNoInput
              key={option}
              option={option}
              isSelected={selectedOptions.includes(option)}
              onSelect={handleSelect}
            />
          ))}
        </form>
      </div>
    </div>
  );
};

export default Step8;
