import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import LogoEvento from '../assets/images/logo.svg';
import LogoMBC from '../assets/images/icone_mbc.svg';

const Processing: React.FC = () => {
  return (
    <div className="flex flex-col text-white min-h-screen justify-center items-center">
      
      <header className="p-[24px] absolute top-4 left-0 right-0 h-16 flex justify-center items-center ">
        <div className="flex justify-between items-center w-[320px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1400px]">
          <img src={LogoEvento} alt="Lumine"  className="w-[145px] lg:w-[180px]" />
          <img src={LogoMBC} alt="Lumine" className="w-[35px] lg:w-[46px]" />
        </div>
      </header>


      <h2 className="text-4xl font-bold mb-4"><CircularProgress className='icon_loading' /></h2>
      <p className="w-[220px] text-center text-[18px] font-500">Estamos descobrindo com que santo você se parece...</p>
    </div>
  );
};

export default Processing;
