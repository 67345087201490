import React from 'react';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../assets/images/sucesso.webp';
import LogoEvento from '../assets/images/logo_evento.svg';

// Santos images
import SaoPadrePio from '../assets/images/santos/saopadrepio.webp';
import SantaClaraDeAssis from '../assets/images/santos/santaclaradeassis.webp';
import SantaTeresaDAvila from '../assets/images/santos/santateresadavila.webp';
import SaoJoaoPauloII from '../assets/images/santos/saojoaopauloii.webp';
import SaoTomasDeAquino from '../assets/images/santos/saotomasdeaquino.webp';
import SaoFranciscoDeAssis from '../assets/images/santos/saofranciscodeassis.webp';
import SantaGiannaBerettaMolla from '../assets/images/santos/santagiannaberettamolla.webp';
import SantaTeresinhaDoMeninoJesus from '../assets/images/santos/santateresinhadomeninojesus.webp';

const Profile: React.FC = () => {
  const navigate = useNavigate();

  const profile = localStorage.getItem('profile') || '';

  const getSantoImage = (profile: string) => {
    switch (profile) {
      case 'São Padre Pio':
        return SaoPadrePio;
      case 'Santa Clara de Assis':
        return SantaClaraDeAssis;
      case 'Santa Teresa D\'Ávila':
        return SantaTeresaDAvila;
      case 'São João Paulo II':
        return SaoJoaoPauloII;
      case 'São Tomás de Aquino':
        return SaoTomasDeAquino;
      case 'São Francisco de Assis':
        return SaoFranciscoDeAssis;
      case 'Santa Gianna Beretta Molla':
        return SantaGiannaBerettaMolla;
      case 'Santa Teresinha do Menino Jesus':
        return SantaTeresinhaDoMeninoJesus;
      default:
        return '';
    }
  };

  const santoImage = getSantoImage(profile);

  const handleNewTest = () => {
    navigate('/');
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {profile && (
        <div className="flex items-center flex-col md:flex-col lg:flex-col xl:flex-row mt-16 md:w-[600px] xl:w-[1200px] xl:justify-center">
          <div>
            <img src={LogoEvento} alt="Logo" className='m-auto pb-4 xl:m-0' />
            <h3 className="text-center md:text-center xl:text-left text-4xl font-bold mb-4">Parabéns!</h3>
            <p className="w-[380px] xl:mb-4 text-center md:text-center xl:text-left text-xl">Suas virtudes lembram as de {profile}</p>
            <div className="hidden xl:block flex justify-center md:justify-center lg:justify-center xl:justify-start ">
              <button
                onClick={handleNewTest}
                className="text-xl w-[580px] max-w-xs py-4 text-white font-bold rounded-full shadow-lg"
                style={{
                  borderRadius: '1000px',
                  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), #0090FF',
                  backgroundBlendMode: 'soft-light, normal',
                  boxShadow: '0px 4px 150px 0px rgba(0, 99, 243, 0.90)'
                }}
              >
                Fazer um novo teste
              </button>
            </div>
          </div>
          <div>
            {santoImage && (
              <img src={santoImage} alt={profile} className='w-[860px] sm:w-[500px] rounded-lg shadow-lg' />
            )}
            <div className="block mb-14 xl:hidden flex justify-center md:justify-center lg:justify-center xl:justify-start ">
              <button
                onClick={handleNewTest}
                className="text-xl w-[580px] max-w-xs py-4 text-white font-bold rounded-full shadow-lg"
                style={{
                  borderRadius: '1000px',
                  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), #0090FF',
                  backgroundBlendMode: 'soft-light, normal',
                  boxShadow: '0px 4px 150px 0px rgba(0, 99, 243, 0.90)'
                }}
              >
                Fazer um novo teste
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
