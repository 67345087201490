import React, { useState } from 'react';
import { useScore } from '../context/ScoreContext';
import StepHeader from '../components/StepHeader';
import SelectionOption from '../components/SelectionOption';

interface Step3Props {
  onNext: () => void;
  onBack: () => void;
}

const Step3: React.FC<Step3Props> = ({ onNext, onBack }) => {
  const { surveyData, updateSelections, resetProfilePoints, addPointsToProfile } = useScore();
  const [selectedOption, setSelectedOption] = useState<string>(surveyData.selections['step3']?.[0] || '');

  const handleSelect = (value: string) => {
    setSelectedOption(value);
    updateSelections('step3', [value]);
  };

  const handleNext = () => {
    if (selectedOption) {
      resetProfilePoints('step3');
      updateSelections('step3', [selectedOption]);
      updateProfilesForOption(selectedOption);
    }
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  const updateProfilesForOption = (value: string) => {
    switch (value) {
      case "Me adapto rapidamente e para encontrar novas soluções":
        addPointsToProfile('São João Paulo II');
        addPointsToProfile('Santa Clara de Assis');
        break;
      case "Prefiro manter a calma e reavaliar a situação antes de decidir":
        addPointsToProfile('São Tomás de Aquino');
        addPointsToProfile('Santa Gianna Beretta Molla');
        break;
      case "Vejo as mudanças como oportunidades para explorar algo diferente":
        addPointsToProfile('São Francisco de Assis');
        addPointsToProfile('Santa Teresa d’Avilla');
        break;
      case "Posso me sentir desconfortável no início, mas me ajusto com o tempo":
        addPointsToProfile('São Padre Pio');
        addPointsToProfile('Santa Teresinha do Menino Jesus');
        break;
      default:
        break;
    }
  };

  const options = [
    "Me adapto rapidamente e para encontrar novas soluções",
    "Prefiro manter a calma e reavaliar a situação antes de decidir",
    "Vejo as mudanças como oportunidades para explorar algo diferente",
    "Posso me sentir desconfortável no início, mas me ajusto com o tempo"
  ];

  return (
    <div className="flex flex-col items-center text-white min-h-screen max-w-screen-xl px-8 py-4 m-auto w-[370px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1400px]">
     
      <StepHeader
        stepNumber={2}
        title="Qual é sua reação mais comum diante de mudanças inesperadas?"
        description="Selecione uma opção:"
      />

      <div className="mb-28 flex-grow overflow-y-auto md:mt-5 mr-4 ml-4 m-auto w-full">
        <form className="grid grid-cols-1 gap-2 mx-auto md:px-0 w-full">
          {options.map(option => (
            <SelectionOption
              key={option}
              option={option}
              isSelected={selectedOption === option}
              onSelect={handleSelect}
            />
          ))}
        </form>
      </div>

    </div>
  );
};

export default Step3;
