import React, { useState, useEffect } from 'react';
import { TextField, CircularProgress, Modal, Box } from '@mui/material';
import { activeCampaignService } from '../api/ActiveCampaing';
import { useScore } from '../context/ScoreContext';
import { usePlataforma } from '../context/PlataformaContext';
import { useNavigate } from 'react-router-dom';
import Cadeado from '../assets/images/lock.svg';

interface Step1Props {
  onNext: () => void;
}

const Step1: React.FC<Step1Props> = ({ onNext }) => {
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [selectedGender, setSelectedGender] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [genderError, setGenderError] = useState<string>('');
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);

  const { setEmail: setSurveyEmail, setGender: setSurveyGender } = useScore(); // Hook to update the email in context
  const { plataforma } = usePlataforma(); // Hook para obter o UTM
  const navigate = useNavigate();

  useEffect(() => {
    setIsFormValid(!!name && !!email && !!phone && !!selectedGender);
  }, [name, email, phone, selectedGender]);

  const handleGenderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedGender(event.target.value);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;
    value = value.replace(/\D/g, ''); // Remove tudo que não é dígito
    value = value.replace(/^(\d{2})(\d)/g, '($1) $2'); // Coloca parênteses em volta dos dois primeiros dígitos
    value = value.replace(/(\d)(\d{4})$/, '$1-$2'); // Coloca hífen entre o quarto e o quinto dígitos
  
    if (value.length <= 15) { // Limita a entrada a 15 caracteres, considerando os caracteres extras da máscara
      setPhone(value);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isFormValid) {
      setNameError(!name ? 'Nome é obrigatório' : '');
      setEmailError(!email ? 'E-mail é obrigatório' : '');
      setPhoneError(!phone ? 'Telefone é obrigatório' : '');
      setGenderError(!selectedGender ? 'Gênero é obrigatório' : '');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const response = await activeCampaignService.handleActiveCampaignContact(name, email, phone, selectedGender, plataforma);
      if (response.redirectToProfile) {
        localStorage.setItem('profile', response.profile);
        window.location.href = '/profile'; // Redirect for profile
      } else {
        setSurveyEmail(email); // Update the email in context
        setSurveyGender(selectedGender); // Update the gender in context
        onNext();
      }
    } catch (error: unknown) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError('Erro desconhecido. Tente novamente.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleButtonClick = () => {
    if (!isFormValid) {
      setNameError(!name ? 'Nome é obrigatório' : '');
      setEmailError(!email ? 'E-mail é obrigatório' : '');
      setPhoneError(!phone ? 'Telefone é obrigatório' : '');
      setGenderError(!selectedGender ? 'Gênero é obrigatório' : '');
    }
  };

  const handleBackClick = () => {
    navigate('/'); // Navigate to the initial step
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="text-white pt-16 pb-24 p-[24px] min-h-screen m-auto w-[370px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1220px]">
      
      <div className='bg-customBlack pt-[50px] lg:pt-[70px] md:relative md:z-0'>
        <h2 className='leading-tight text-[24px] md:text-[26px] lg:text-[28px] xl:text-[32px] 2xl:text-[40px] font-[500]'>
          Para começar… Me conte qual o seu nome e para qual e-mail devemos mandar o resultado?
        </h2>
      </div>

      <form onSubmit={handleSubmit} className="pt-[40px] flex flex-col w-full mx-auto md:px-0 m-auto">
        <div className="mb-4">
          <TextField
            fullWidth
            label="Nome"
            variant="filled"
            value={name}
            onChange={(e) => setName(e.target.value)}
            error={!!nameError}
            helperText={nameError}
            className="bg-greyAlphaGrey2 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <TextField
            fullWidth
            label="E-mail"
            type="email"
            variant="filled"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            error={!!emailError}
            helperText={emailError}
            className="bg-greyAlphaGrey2 rounded-lg"
          />
        </div>
        <div className="mb-4">
          <TextField
            fullWidth
            label="Telefone"
            type="tel"
            variant="filled"
            value={phone}
            onChange={handlePhoneChange}
            error={!!phoneError}
            helperText={phoneError}
            className="bg-greyAlphaGrey2 rounded-lg"
          />
        </div>
        <div className="mb-4 flex items-center gap-2 sm:gap-6 p-[10px] sm:p-[24px] ">
          <p className='font-[400] text-sm sm:text-base lg:text-lg'>Gênero</p>
          <div className='stroke'></div>
          <div className='text-sm sm:text-base lg:text-lg font-[500] flex gap-5 items-center'>
            <label className="flex items-center gap-2 cursor-pointer">
              <input 
                type="radio"
                name="gender"
                value="Masculino"
                className="h-4 custom-checkbox step0"
                checked={selectedGender === 'Masculino'}
                onChange={handleGenderChange}
              />
              <p>Masculino</p>
            </label>
            <label className="flex items-center gap-2 cursor-pointer">
              <input 
                type="radio"
                name="gender"
                value="Feminino"
                className="h-4 custom-checkbox step0"
                checked={selectedGender === 'Feminino'}
                onChange={handleGenderChange}
              />
              <p>Feminino</p>
            </label>
          </div>
          {genderError && <p className="text-red-500 text-sm">{genderError}</p>}
        </div>
        
        {error && <p className="text-red-500 font-400 text-sm text-center mt-2">{error}</p>}
        
        <footer className='p-[24px] gap-4 bg-customBlack h-[140px] text-white p-4 flex justify-between items-center flex-col md:flex-row fixed bottom-0 left-1/2 transform -translate-x-1/2 m-auto w-[370px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1220px]'>
          <div className='w-full lg:w-[500px] flex gap-[10px] order-2 text-[14px] text-center md:text-left text-greySolidNeutral10 md:text-sm'>
              <img src={Cadeado} alt="Icon security" className='w-[20px] hidden md:block'/>
              <p className='text-[14px] 2xl:text-[16px]'>Ao clicar em próximo você concorda com nossa de <span className='underline cursor-pointer' onClick={handleOpen}>política de privacidade</span></p>
          </div>
          <div className='flex w-full gap-2 md:order-3'>
            <button
              type="button" // Change to "button" to prevent form submission
              className="w-full text-[16px] font-bold bg-customBlack border border-greySolidGrey6 px-6 py-3 rounded-full disabled:opacity-50 md:px-12 md:py-4 md:text-base md:font-bold"
              onClick={handleBackClick} // Add the onClick handler to navigate back
            >
              Voltar
            </button>

            <button
              type="submit"
              className="w-full text-[16px] font-bold bg-white text-black px-6 py-3 rounded-full disabled:opacity-50 md:px-12 md:py-4 md:text-base md:font-bold"
              disabled={!isFormValid || loading}
              onClick={handleButtonClick}
            >
              {loading ? <CircularProgress size={24} className='icon_loading_login' /> : 'Próximo'}
            </button>
          </div>
        </footer>
      </form>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <>
        <Box className="mt-[50px] p-4 w-[370px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1220px] mx-auto my-8 bg-white rounded-[28px] shadow-lg">
          <iframe 
            src="https://clube.bibliotecacatolica.com.br/politica-de-privacidade" 
            width="100%"  
            title="Política de Privacidade" 
            className='h-[72vh]'
          />
        </Box>
        <div className='text-[16px] lg:text-[18px] w-[300px] font-bold m-auto text-center text-black bg-white rounded-full p-4 cursor-pointer' onClick={handleClose}>FECHAR</div>
        </>
      </Modal>
    </div>
  );
};

export default Step1;
