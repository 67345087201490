import React, { useState } from 'react';
import { useScore } from '../context/ScoreContext';
import StepHeader from '../components/StepHeader';
import SelectionOption from '../components/SelectionOption';

interface Step4Props {
  onNext: () => void;
  onBack: () => void;
}

const Step4: React.FC<Step4Props> = ({ onNext, onBack }) => {
  const { surveyData, updateSelections } = useScore();
  const [selectedOption, setSelectedOption] = useState<string>(surveyData.selections['step4']?.[0] || '');

  const handleSelect = (value: string) => {
    setSelectedOption(value);
    updateSelections('step4', [value]);
  };

  const handleNext = () => {
    onNext();
  };

  const handleBack = () => {
    onBack();
  };

  const options = [
    "Intenso",
    "Estável",
    "Alegre",
    "Contemplativo"
  ];

  return (
    <div className="flex flex-col items-center text-white min-h-screen max-w-screen-xl px-8 py-4 m-auto w-[370px] sm:w-[480px] md:w-[700px] lg:w-[700px] xl:w-[900px] 2xl:w-[1400px]">
      
      <StepHeader
        stepNumber={3}
        title="Qual palavra melhor descreve você no seu dia a dia?"
        description="Selecione uma opção:"
      />

      <div className="mb-28 flex-grow overflow-y-auto md:mt-5 mr-4 ml-4 m-auto w-full">
        <form className="grid grid-cols-1 gap-2 mx-auto md:px-0 w-full">
          {options.map(option => (
            <SelectionOption
              key={option}
              option={option}
              isSelected={selectedOption === option}
              onSelect={handleSelect}
            />
          ))}
        </form>
      </div>
      
    </div>
  );
};

export default Step4;
