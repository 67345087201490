import React, { useEffect } from 'react';
import { useScore } from '../context/ScoreContext';
import { useNavigate } from 'react-router-dom';
import BackgroundImage from '../assets/images/sucesso.webp';
import LogoEvento from '../assets/images/logo_evento.svg';
import { activeCampaignService } from '../api/ActiveCampaing';

// Santo images
import SaoPadrePio from '../assets/images/santos/saopadrepio.webp';
import SantaClaraDeAssis from '../assets/images/santos/santaclaradeassis.webp';
import SantaTeresaDAvila from '../assets/images/santos/santateresadavila.webp';
import SaoJoaoPauloII from '../assets/images/santos/saojoaopauloii.webp';
import SaoTomasDeAquino from '../assets/images/santos/saotomasdeaquino.webp';
import SaoFranciscoDeAssis from '../assets/images/santos/saofranciscodeassis.webp';
import SantaGiannaBerettaMolla from '../assets/images/santos/santagiannaberettamolla.webp';
import SantaTeresinhaDoMeninoJesus from '../assets/images/santos/santateresinhadomeninojesus.webp';

const Success: React.FC = () => {
  const { profiles, surveyData } = useScore();
  const navigate = useNavigate();

  // Peso total da pergunta 7
  const step7TotalWeight = 5;

  // Pontuações baseadas nas seleções da pergunta 7
  const step7Selections = surveyData.selections['step8'] || [];
  const santoPoints: { [key: string]: number } = {
    'São João Paulo II': 0,
    'Santa Clara de Assis': 0,
    'São Tomás de Aquino': 0,
    'Santa Gianna Beretta Molla': 0,
    'São Francisco de Assis': 0,
    'Santa Teresa d\'Ávila': 0,
    'São Padre Pio': 0,
    'Santa Teresinha do Menino Jesus': 0
  };

  step7Selections.forEach(selection => {
    switch (selection) {
      case "Determinado":
      case "Corajoso":
      case "Líder":
      case "Perseverante":
      case "Caridoso":
      case "Proativo":
      case "Carismático":
      case "Combativo":
      case "Aventureiro":
      case "Prestativo":
        santoPoints['São João Paulo II']++;
        santoPoints['Santa Clara de Assis']++;
        break;
      case "Equilibrado":
      case "Intelectual":
      case "Paciente":
      case "Observador":
      case "Tranquilo":
      case "Confiante na Providência":
      case "Se sacrifica":
      case "Constante":
      case "Calmo":
      case "Sensato":
        santoPoints['São Tomás de Aquino']++;
        santoPoints['Santa Gianna Beretta Molla']++;
        break;
      case "Comunicativo":
      case "Bondoso":
      case "Apaixonado":
      case "Otimista":
      case "Cativante":
      case "Espontâneo":
      case "Flexível":
      case "Alegre":
      case "Desapegado":
      case "Altruísta":
        santoPoints['São Francisco de Assis']++;
        santoPoints['Santa Teresa d\'Ávila']++;
        break;
      case "Obstinado":
      case "Sensível":
      case "Leal":
      case "Humilde":
      case "Empático":
      case "Contemplativo":
      case "Piedoso":
      case "Dedicado":
      case "Generoso":
      case "Simples":
        santoPoints['São Padre Pio']++;
        santoPoints['Santa Teresinha do Menino Jesus']++;
        break;
      default:
        break;
    }
  });

  // Calcular pontuação final com base na pergunta 7
  const totalSelections = step7Selections.length;
  (Object.keys(santoPoints) as (keyof typeof santoPoints)[]).forEach(santo => {
    if (totalSelections > 0) {
      santoPoints[santo] = (santoPoints[santo] / totalSelections) * step7TotalWeight + (profiles[santo] || 0);
    } else {
      santoPoints[santo] = profiles[santo] || 0;
    }
  });

  // Ordenar os santos com base na pontuação final
  const sortedSantos = Object.entries(santoPoints).sort(([, a], [, b]) => b - a);
  
  // Debug: Mostrar as pontuações dos santos no console
  console.log('Pontuações dos santos:', sortedSantos);

  // Filtrar os santos com base no gênero
  const isFemale = surveyData.gender === 'Feminino';
  console.log('Gênero:', surveyData.gender); // Adicionado para verificar o gênero
  const santosMapping: { [key: string]: { male: string; female: string } } = {
    'São João Paulo II': { male: 'São João Paulo II', female: 'Santa Clara de Assis' },
    'Santa Clara de Assis': { male: 'São João Paulo II', female: 'Santa Clara de Assis' },
    'São Tomás de Aquino': { male: 'São Tomás de Aquino', female: 'Santa Gianna Beretta Molla' },
    'Santa Gianna Beretta Molla': { male: 'São Tomás de Aquino', female: 'Santa Gianna Beretta Molla' },
    'São Francisco de Assis': { male: 'São Francisco de Assis', female: 'Santa Teresa d\'Ávila' },
    'Santa Teresa d\'Ávila': { male: 'São Francisco de Assis', female: 'Santa Teresa d\'Ávila' },
    'São Padre Pio': { male: 'São Padre Pio', female: 'Santa Teresinha do Menino Jesus' },
    'Santa Teresinha do Menino Jesus': { male: 'São Padre Pio', female: 'Santa Teresinha do Menino Jesus' },
  };

  const filteredSantos = sortedSantos.filter(([santo]) => {
    const mappedSanto = santosMapping[santo];
    return isFemale ? mappedSanto?.female : mappedSanto?.male;
  });

  const topSanto = filteredSantos[0]?.[0];
  const finalSanto = isFemale ? santosMapping[topSanto]?.female : santosMapping[topSanto]?.male;

  useEffect(() => {
    if (surveyData.email && finalSanto) {
      console.log(`Enviando dados para updateProfiles: email=${surveyData.email}, profiles=${finalSanto}`);
      activeCampaignService.updateProfiles(surveyData.email, finalSanto)
        .then(response => {
          console.log('Profile updated successfully:', response);
        })
        .catch(error => {
          console.error('Error updating profile:', error);
        });
    }
  }, [surveyData.email, finalSanto]);

  const getSantoImage = (santo: string) => {
    switch (santo) {
      case 'São Padre Pio':
        return SaoPadrePio;
      case 'Santa Clara de Assis':
        return SantaClaraDeAssis;
      case 'Santa Teresa d\'Ávila':
        return SantaTeresaDAvila;
      case 'São João Paulo II':
        return SaoJoaoPauloII;
      case 'São Tomás de Aquino':
        return SaoTomasDeAquino;
      case 'São Francisco de Assis':
        return SaoFranciscoDeAssis;
      case 'Santa Gianna Beretta Molla':
        return SantaGiannaBerettaMolla;
      case 'Santa Teresinha do Menino Jesus':
        return SantaTeresinhaDoMeninoJesus;
      default:
        return '';
    }
  };

  const santoImage = getSantoImage(finalSanto);

  const handleNewTest = () => {
    navigate('/');
  };

  return (
    <div
      className="flex flex-col items-center justify-center min-h-screen bg-cover bg-center"
      style={{ backgroundImage: `url(${BackgroundImage})` }}
    >
      {finalSanto && (
        <div className="flex items-center flex-col md:flex-col lg:flex-col xl:flex-row mt-16 md:w-[600px] xl:w-[1200px] xl:justify-center">
          <div>
          <img src={LogoEvento} alt="Logo" className='m-auto pb-4 xl:m-0'/>
          <h3 className="text-center md:text-center xl:text-left text-4xl font-bold mb-4">Parabéns!</h3>
          <p className="w-[380px] xl:mb-4 text-center md:text-center xl:text-left text-xl">Suas virtudes lembram as de {finalSanto}</p>
            <div className="hidden xl:block flex justify-center md:justify-center lg:justify-center xl:justify-start ">
              <button
                onClick={handleNewTest}
                className="text-xl w-[580px] max-w-xs py-4 text-white font-bold rounded-full shadow-lg"
                style={{
                  borderRadius: '1000px',
                  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), #0090FF',
                  backgroundBlendMode: 'soft-light, normal',
                  boxShadow: '0px 4px 150px 0px rgba(0, 99, 243, 0.90)'
                }}
              >
                Fazer um novo teste
              </button>
            </div>
          </div>
          <div>
            <img src={santoImage} alt={finalSanto} className='w-[860px] sm:w-[500px] rounded-lg shadow-lg' />
            <div className="block mb-14 xl:hidden flex justify-center md:justify-center lg:justify-center xl:justify-start ">
              <button
                onClick={handleNewTest}
                className="text-xl w-[580px] max-w-xs py-4 text-white font-bold rounded-full shadow-lg"
                style={{
                  borderRadius: '1000px',
                  background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.60) 0%, rgba(0, 0, 0, 0.60) 100%), #0090FF',
                  backgroundBlendMode: 'soft-light, normal',
                  boxShadow: '0px 4px 150px 0px rgba(0, 99, 243, 0.90)'
                }}
              >
                Fazer um novo teste
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Success;
